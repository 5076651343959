.footer-dark {
    padding: 50px 0;
    color: #000000;
    background-image: url("/src/Images/wave.svg");
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    /* background-color: dimgray; */
    background-size:cover;
  }
  
  .footer-dark h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .footer-dark ul {
    padding: 0;
    list-style: none;
    line-height: 36px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
  }
  
  .footer-dark ul a:hover {
    opacity: 0.8;
  }
  
  @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align: center;
      padding-bottom: 20px;
    }
  }
  
  .footer-dark .item.text {
    margin-bottom: 36px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom: 0;
    }
  }
  
  .footer-dark .item.social {
    text-align: start;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align: center;
      margin-top: 20px;
    }
  }
  
  .footer-dark .item.social > a {
    font-size: 20px;
    width: 36px;
    height: 36px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    margin: 0 8px;
    color: #000000;
    opacity: 0.75;
  }
  
  .footer-dark .item.social > a:hover {
    opacity: 0.9;
  }
  
  .footer-dark .copyright {
    text-align: start;
    padding-top: 24px;
    opacity: 0.3;
    font-size: 13px;
    margin-bottom: 0;
  }
  .footer-dark .ContactUs li {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    font-size: 14px;
    color: #000000;
    margin-bottom: 15px;
  }
  .footer-dark .ContactUs li em {
    color: #000000;
    font-style: normal;
    margin-inline-end: 10px;
    font-weight: 700;
  }
  .footer-dark .caption {
    line-height: 36px;
    margin-bottom: 18px;
    font-size: 16px;
    color: #000000;
    font-weight: 700;
  }