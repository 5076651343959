.navigation-clean {
    background: #fff;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #333;
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
  }
  
  @media (min-width:768px) {
    .navigation-clean {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  
  .navigation-clean .navbar-brand {
    font-weight: bold;
    color: inherit;
  }

  .navigation-clean .navbar-brand img {
    height: 50px;
  }
  
  .navigation-clean .navbar-brand:hover {
    color: #222;
  }
  
  .navigation-clean.navbar-dark .navbar-brand:hover {
    color: #ddd;
  }
  
  .navigation-clean .navbar-toggler {
    border-color: #ddd;
  }
  
  .navigation-clean .navbar-toggler:hover, .navigation-clean .navbar-toggler:focus {
    background: none;
  }
  
  .navigation-clean.navbar-dark .navbar-toggler {
    border-color: #555;
  }
  
  .navigation-clean .navbar-toggler {
    color: #888;
  }
  
  .navigation-clean.navbar-dark .navbar-toggler {
    color: #eee;
  }
  
  .navigation-clean .navbar-collapse, .navigation-clean .form-inline {
    border-top-color: #ddd;
  }
  
  .navigation-clean.navbar-dark .navbar-collapse, .navigation-clean.navbar-dark .form-inline {
    border-top-color: #333;
  }
  .navigation-clean.navbar .navbar-nav{
    align-items: center;
  }
  .navigation-clean.navbar .navbar-nav .nav-link {
    padding-left: 18px;
    padding-right: 18px;
  }
  
  .navigation-clean .navbar-nav > li > .dropdown-menu {
    box-shadow: none;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item {
    line-height: 2;
    color: #F0C5B4;
  }
  
  .navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
    background: #eee;
    color: inherit;
  }
  
  .navigation-clean.navbar-dark {
    color: #FF0000;
  }

  /* .bg-dark {
    background-image:linear-gradient( to left,#C03936,#D25745,#E4937E, #F7E2DF) !important;
  } */

  .bg-dark {
    background-image:linear-gradient( to left,#cd9494,#cd9494,#C03936, #C03936) !important;
  }
  
  .navigation-clean.navbar-dark .navbar-nav a.active, .navigation-clean.navbar-dark .navbar-nav a.active:focus, .navigation-clean.navbar-dark .navbar-nav a.active:hover {
    color: #8f8f8f;
    box-shadow: none;
    background: none;
    pointer-events: none;
  }
  
  .navigation-clean.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    font-weight: 700;
  }
  
  .navigation-clean.navbar-dark .navbar-nav .nav-link:focus, .navigation-clean.navbar-dark .navbar-nav .nav-link:hover {
    color: #000;
    background-color: #ddd;
  }
  
  .navigation-clean.navbar-dark .navbar-nav > li > .dropdown-menu {
    background-color: #fff;
    padding: 5px;
  }
  
  .navigation-clean.navbar-dark .dropdown-menu .dropdown-item:focus, .navigation-clean.navbar-dark .dropdown-menu .dropdown-item {
    color: #000;
    border-radius: 10px;
  }
  
  .navigation-clean.navbar-dark .dropdown-menu .dropdown-item:focus, .navigation-clean.navbar-dark .dropdown-menu .dropdown-item:hover {
    background: #ddd;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
 }
  