.AboutContiner {
  margin-block: 50px;
}
.AboutContiner .caption,
.AboutContiner .discription {
  font-size: 50px;
  color: #333;
  margin-bottom: 25px;
  margin-inline-start: 25px;
}
.AboutContiner .discription {
  font-size: 16px;
}
.AboutContiner .caption::after {
  content: "";
  display: block;
  width: 70px;
  height: 6px;
  background: #C03936;
  margin-top: 18px;
}

/* .AboutContiner img {
  border-top-left-radius: 50px;
  width: 100%;
} */
.AboutContiner img{
  border-top-left-radius: 50px;
  width: 100%;
  height:600px ;
  /* background-image:linear-gradient( to left,#C03936,#D25745,#E4937E, #F7E2DF) !important; */
}
