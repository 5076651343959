.ProductTable {
    background-color: white;
    max-width: 100%;
    overflow-x: auto;
    width: fit-content;
}
.ProductTable th{
    background-color: #1167b1;
    color: white;
    padding: 30px;
    text-align: center;
}
.ProductTable td{
    text-align: center;
    padding: 30px;
    border-color: lightgray;
    border-width: 1px;
}


.ProductTableWithoutPadding {
    background-color: white;
    max-width: 100%;
    overflow-x: scroll;
}
.ProductTableWithoutPadding th{
    background-color: #1167b1;
    color: white;
    padding: 30px;
    text-align: center;
}
.ProductTableWithoutPadding td{
    text-align: center;
    padding-block: 30px;
    border-color: lightgray;
    border-width: 1px;

}