.ProductDetailContiner {
  margin-block: 100px;
  margin-inline: 20px;
}
.ProductImge {
  width: 90%;
}
.ProductDetailContiner .caption,
.ProductDetailContiner .discription {
  font-size: 36px;
  color: #333;
  margin-bottom: 25px;
  margin-inline-start: 25px;
}
.ProductDetailContiner .discription {
  font-size: 16px;
}
.ProductDetailContiner .caption::after {
  content: "";
  display: block;
  width: 70px;
  height: 6px;
  background: #C03936;
  margin-top: 18px;
}
.ProductDetailContiner .TestMenulist ul {
  background: #fff;
  box-shadow: 2px 2px 30px 0px rgb(0 0 0 / 8%);
}
.ProductDetailContiner .TestMenulist li {
  list-style: none;
  background: #fff;
  box-shadow: 2px 2px 30px 0px rgb(0 0 0 / 8%);
  width: 50%;
  margin-bottom: 50px;
}

.ProductDetailContiner .TestMenulist li .TestHeader {
  padding: 20px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ProductDetailContiner .TestMenulist li :hover {
  background-color: #0075c2;
  color: #fff;
}

.ProductDetailContiner .TestMenulist li .TestHeader .captionTestName {
  font-size: 20px;
  font-weight: 500;
}
.ProductDetailContiner .TestMenulist li .TestDetails {
  background-color: #fff;
  min-height: 500px;
  display: none;
}
.Benefits{
    margin-block: 50px;
    margin-inline-start: 20px;
}

.Benefits .BenefitItems .BenefitItemsTitle{
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-top: 10px;
    margin-inline-start: 25px;
}
.Benefits .BenefitItems .BenefitItemsTitle::after{
    content: "";
    display: block;
    width: 30px;
    height: 1.5px;
    background: #0075c2;
    margin-top: 10px;
}
.Benefits .BenefitItems .BenefitItemsdescription{
    font-size: 16px;
    margin-top: 10px;
    margin-inline-start: 25px;
}

