.MaterialsContiner {
background-image: linear-gradient(
to left,
#6495ED,#6495ED,#6495ED, #1167b1
) !important;
}
.imgeContiner {
margin-inline: 1px;
height: 600px;
overflow: hidden;
}
.imgeContiner img {
border-top-left-radius: 100px;
width: 100%;
height: 100%;
image-rendering: optimizeSpeed;
}
.AboutMaterialsContiner {
margin-block: 100px;
margin-inline: 20px;
}
.AboutMaterialsContiner .caption,
.AboutMaterialsContiner .discription {
font-size: 50px;
color: #333;
margin-bottom: 25px;
margin-inline-start: 25px;
}
.AboutMaterialsContiner .caption::after {
content: "";
display: block;
width: 70px;
height: 6px;
background: #c03936;
margin-top: 18px;
}
.AboutMaterialsContiner .discription {
font-size: 16px;
}
.AboutMaterialsContiner img {
width: 100%;
/* min-height: 450px;
max-height: 450px; */
}

.aboutMaterialsImg {
width: 100%;
background-image: linear-gradient(
to left,
#c03936,
#d25745,
#e4937e,
#f7e2df
) !important;
}

.relatedProductlist {
display: flex;
flex-flow: wrap;
}
.relatedProductlist li {
list-style: none;
padding: 20px;
min-width: 30%;
margin-inline: 10px;
margin-block: 20px;
background-image: linear-gradient(#ffffff, #ffffff),
linear-gradient(#00a0e9, #00a0e9);
background-blend-mode: normal, normal;
box-shadow: 3px 4px 22px 0px rgb(84 84 84 / 7%);
border-radius: 10px;
}
@media only screen and (min-width: 600px) {
.relatedProductlist li {
max-width: 30%;
}
.index-more {
width: 50%;
line-height: 50px;
}
}
@media only screen and (max-width: 600px) {
.index-more {
line-height: 40px;
width: 50%;
}
}
.relatedProductlist li .name {
text-align: center;
font-size: 18px;
margin-block-start: 10px;
margin-block-end: 40px;
}
.relatedProductlist li .index-more {
position: relative;
top: 50px;
margin: 0 auto;
}

.index-more {
display: block;
background-color: #0075c2;
border-radius: 20px 0px 20px 0px;
text-align: center;
font-size: 16px;
color: #fff;
}
.index-more a {
text-decoration: none;
color: #ffffff;
}
.index-more:hover {
background-color: orange;
}
.animatedImg {
transition: transform 0.8s ease;
}
.animatedImg:hover {
transform: scale(1.05);
}

.side-tab {
display: flex;
align-items: center;
justify-content: center;
background-color: lightgrey !important;
margin-block: 0.5rem;
color: black;
height: 4rem;
text-align: center;
border-end-end-radius: 1.5rem !important;
border-start-start-radius: 1.5rem !important;
margin-inline-start: 25px;
}
.side-tab.active {
background-color: #0075c2 !important;
margin-block: 0.5rem;
color: black;
}
.side-tab:hover {
background-color: #0075c2 !important;
margin-block: 0.5rem;
color: white;
}
