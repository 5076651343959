.SliderContiner {
  /* background-image:linear-gradient( to left,#6495ED,#6495ED,#6495ED, #1167b1) !important; */
  height: 600px;
}
.slider {
  /* border-top-left-radius: 100px; */
  height: 600px;
}
.HomePageSlider img {
  width: 100%;
}

@media (max-width:480px) {
  .SliderContiner {
    height: 400px;
  }
  .slider {
    height: 400px;
  }
  .HomePageSlider img {
    width: 100%;
  }
}

.slider a.previousButton,
.slider a.nextButton {
  background-color: rgb(255, 255, 255, 0.5);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 50px;
}

.slider a.previousButton:hover,
.slider a.nextButton:hover {
  background-color: rgb(255, 255, 255, 1);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 50px;
}
.slider a.previousButton polygon,
.slider a.nextButton polygon {
  fill: dimgray;
}
.slider a.previousButton svg,
.slider a.nextButton svg {
  width: 10px;
  margin-inline-start: 10px;
}
